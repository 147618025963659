:root {
  --space-cadet: #22223B;
  --ultra-violet: #4A4E69;
  --rose-quartz: #9A8C98;
  --pale-dogwood: #C9ADA7;
  --isabelline: #F2E9E4;
}

* {
  background-color: var('transparent');
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--isabelline);
  background-color: var(--rose-quartz);
}

::selection {
  color: var(--isabelline);
  background-color: var(--rose-quartz);
}

/* [data-theme='dark'] {
  background-color: var('transparent');
} */